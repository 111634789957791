#page-landing {
    height: 100vh;
    width: 100vw;
    background: #e6e6e6;

    display: flex;
    justify-content: center;
    align-items: center;
}
#page-landing .content-wrapper {
    width:100%;
    max-width: 1100px;
    height: 100%;
    max-height: 680px;

    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}
.content-wrapper .location {
    position:absolute;
    font-size: 11px;
    bottom: 10px;
}

.content-wrapper .social-media {
}

.content-text {
    margin-top: 15px;
    margin-bottom: 15px;
    text-align: center;
    color: #333;
}