*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body{
    color: black;
    background: #e6e6e6;
}

body, input, button, textarea{
    font: 600 18px 'Nunito', sans-serif;
}